import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { Login, LoginFunction } from '@heltti/components';

import { getUrlForEident } from '../../api';
import { RootState } from '../../ducks';
import { login as loginAction } from '../../ducks/auth';
import { GetVerificationUrlFunction } from '@heltti/components/dist/components/Login/VerifyButton';

type PlainLoginPageProps = RouteComponentProps<
    Record<string, string | undefined>,
    Record<string, string | undefined>,
    { referrer: string | undefined }
> & {
    error: string | undefined;
    getVerificationUrl: GetVerificationUrlFunction;
    loading: boolean;
    login: LoginFunction;
};

const PlainLoginPage: React.FC<PlainLoginPageProps> = (props) => {
    const { login, loading, location, error, getVerificationUrl } = props;
    const { state } = location;

    return (
        <div id="app">
            <div id="contentScroll">
                <Login
                    login={login}
                    error={error}
                    loading={loading}
                    redirectUrl={state?.referrer}
                    getVerificationUrl={getVerificationUrl}
                />
            </div>
        </div>
    );
};

export const LoginPage = connect(
    ({ auth: { isLoggingIn, error } }: RootState) => ({
        loading: isLoggingIn,
        error,
        getVerificationUrl: getUrlForEident
    }),
    {
        login: loginAction
    }
)(PlainLoginPage);
