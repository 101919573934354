import React, { FunctionComponent } from 'react';
import { gql, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';

import { flatDiff, getNodes } from '@heltti/common';
import { Error, Loader } from '@heltti/components';

import {
    CompanyDetailsEditMutation,
    CompanyDetailsEditMutationVariables,
    CompanyDetailsQuery,
    CompanyDetailsQueryVariables
} from '../graphql-schema';

import { CompanyForm, CompanyFormData } from './CompanyForm';
import { useLoadingQuery } from '../hooks/loading-query';

const FRAGMENT_COMPANY_DETAILS = gql`
    fragment CompanyDetailsFragment on CompanyNode {
        id
        membersCanReportSickLeave
        sickLeaveMaxDays
        sickLeavePractice
    }
`;

const FRAGMENT_CALENDAR_EVENT = gql`
    fragment CalendarEventFragment on CalendarEventNode {
        id
        start
        end
    }
`;

const QUERY_COMPANY_DETAILS = gql`
    query CompanyDetails($companyId: ID!) {
        root {
            company(id: $companyId) {
                ...CompanyDetailsFragment

                calendar {
                    id
                    events(eventTypes: ["COMPANY_FISCAL_PERIOD"], last: 1) {
                        edges {
                            node {
                                ...CalendarEventFragment
                            }
                        }
                    }
                }
            }
        }
    }

    ${FRAGMENT_COMPANY_DETAILS}
    ${FRAGMENT_CALENDAR_EVENT}
`;

const MUTATION_COMPANY_DETAILS_EDIT = gql`
    mutation CompanyDetailsEdit(
        $companyData: CompanyEditMutationInput!
        $calendarEventData: CalendarEventEditMutationInput!
    ) {
        companyEdit(input: $companyData) {
            company {
                ...CompanyDetailsFragment
            }
        }
        calendarEventEdit(input: $calendarEventData) {
            calendarEvent {
                ...CalendarEventFragment
            }
        }
    }

    ${FRAGMENT_COMPANY_DETAILS}
    ${FRAGMENT_CALENDAR_EVENT}
`;

export type CompanyEditFormProps = { companyId: string; open: boolean; close: () => void };

export const CompanyEditForm: FunctionComponent<CompanyEditFormProps> = (props) => {
    const { companyId, open, close } = props;

    const skip = companyId === undefined;

    const {
        data,
        loading: queryLoading,
        error: queryError
    } = useLoadingQuery<CompanyDetailsQuery, CompanyDetailsQueryVariables>(QUERY_COMPANY_DETAILS, {
        skip,
        variables: { companyId }
    });

    const [companyEdit, { loading: mutationLoading, error: mutationError }] = useMutation<
        CompanyDetailsEditMutation,
        CompanyDetailsEditMutationVariables
    >(MUTATION_COMPANY_DETAILS_EDIT, { onCompleted: () => close() });

    if (queryLoading) {
        return <Loader />;
    }

    if (queryError) {
        return <Error />;
    }

    const { membersCanReportSickLeave, sickLeavePractice, sickLeaveMaxDays } = data?.root?.company ?? {};
    const { id: calendarEventId, start, end } = getNodes(data?.root?.company?.calendar?.events)?.[0] ?? {};

    const onSubmit = async (values: CompanyFormData) => {
        const { sickLeaveMaxDays, sickLeavePractice, membersCanReportSickLeave, fiscalStartDate, fiscalEndDate } =
            values;

        const companyData = {
            companyId,
            ...flatDiff(initialValues, { sickLeaveMaxDays, sickLeavePractice, membersCanReportSickLeave })
        };
        const { fiscalStartDate: start, fiscalEndDate: end } = flatDiff(initialValues, {
            fiscalStartDate,
            fiscalEndDate
        });
        const calendarEventData = {
            calendarEventId: calendarEventId,
            start,
            end
        };

        if (!mutationLoading) {
            try {
                await companyEdit({
                    variables: {
                        companyData,
                        calendarEventData
                    }
                });
            } catch (error) {
                Sentry.captureMessage(`Company edit mutation failed: ${error}`, 'warning');
            }
        }
    };

    const onCancel = () => {
        close();
        return Promise.resolve();
    };

    const initialValues: CompanyFormData = {
        fiscalStartDate: start ?? '',
        fiscalEndDate: end ?? '',
        membersCanReportSickLeave: membersCanReportSickLeave ?? false,
        sickLeaveMaxDays: sickLeaveMaxDays ?? 0,
        sickLeavePractice: sickLeavePractice ?? ''
    };

    return (
        <>
            <CompanyForm
                open={open}
                loading={mutationLoading}
                initialValues={initialValues}
                onSubmit={onSubmit}
                onCancel={onCancel}
            />

            {mutationError ? <Error  description={mutationError.message}/> : null}
        </>
    );
};
