import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';

import {
    TopNavigationBar,
    TopNavigationBarItemLink,
    TopNavigationBarSubmenu,
    TopNavigationBarSubmenuItem,
    TopNavigationBarSubmenuItemDivider,
    TopNavigationBarSubmenuItemLink
} from '@heltti/components';

import t from './../translations';
import { AppPrivateRouteParams } from './App';
import { myHelttiHostName } from '../utils/hostname';
import { UserProfileContextType, useUserProfile } from '../context/UserProfile';
import { useAppLanguage } from '../context/AppLanguage';
import { logout } from '../ducks';

type CompanyAccessMenuProps = {
    companyAccess: UserProfileContextType['companyAccess'];
    currentCompanyId: string;
};

const CompanyAccessMenu: React.FC<CompanyAccessMenuProps> = (props) => {
    const { companyAccess, currentCompanyId } = props;

    const numCompanies = Object.keys(companyAccess).length;

    if (numCompanies === 0) {
        return null;
    }

    const currentCompanyName = companyAccess[currentCompanyId];

    if (numCompanies === 1) {
        return <TopNavigationBarItemLink title={currentCompanyName} to="" disabled position="right" />;
    }

    return (
        <TopNavigationBarSubmenu title={currentCompanyName} position="right">
            {Object.entries(companyAccess).map(([companyId, companyName]) => (
                <TopNavigationBarSubmenuItemLink
                    key={companyId}
                    title={companyName}
                    disabled={currentCompanyId === companyId}
                    to={`/${companyId}/home`}
                />
            ))}
        </TopNavigationBarSubmenu>
    );
};

export const AppNavigationBar: React.FC = () => {
    const intl = useIntl();

    const { replace } = useHistory();
    const dispatch = useDispatch();

    const { language } = useAppLanguage();
    const { firstName, companyAccess } = useUserProfile()!;
    const { companyId: currentCompanyId } = useParams<AppPrivateRouteParams>();

    const hostname = myHelttiHostName();

    const onLogout = useCallback(() => {
        logout()(dispatch).finally(() => replace(`/login?lang=${language}`));
    }, [dispatch, language, replace]);

    return (
        <TopNavigationBar defaultRoute="/home">
            <TopNavigationBarItemLink
                title={intl.formatMessage(t.menuHome)}
                to={`/${currentCompanyId}/home`}
                iconName="clipboard"
            />
            <TopNavigationBarItemLink
                title={intl.formatMessage(t.employeesTitle)}
                to={`/${currentCompanyId}/employments`}
                iconName="group"
            />
            <TopNavigationBarItemLink
                title={intl.formatMessage(t.documentsTitle)}
                to={`/${currentCompanyId}/documents`}
                iconName="file"
            />

            <CompanyAccessMenu companyAccess={companyAccess} currentCompanyId={currentCompanyId} />

            <TopNavigationBarSubmenu title={firstName}>
                <TopNavigationBarSubmenuItem
                    title={intl.formatMessage(t.profileTitle)}
                    as="a"
                    href={`${hostname}/profile`}
                    iconName="external"
                />
                <TopNavigationBarSubmenuItem title="MyHeltti" as={'a'} href={`${hostname}/home`} iconName="external" />
                <TopNavigationBarSubmenuItemDivider />
                <TopNavigationBarSubmenuItem
                    title={intl.formatMessage(t.logoutButton)}
                    onClick={onLogout}
                    iconName="log out"
                />
            </TopNavigationBarSubmenu>
        </TopNavigationBar>
    );
};
