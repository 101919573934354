import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { flowRight as compose } from 'lodash';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { AcceptTermsModal, Error, Loader, Version } from '@heltti/components';

import { acceptTerms, initAuthentication, refreshAuthentication, RootState } from '../ducks';

import './app.less';

import { PublicRoute } from './PublicRoute';
import { HomePage } from '../views/Home';
import { NotFound404 } from '../views/404';
import { LoginPage } from '../views/Login';
import { EmploymentsPage } from '../views/Employments';
import { DocumentsPage } from '../views/Documents';
import { NotVerifiedPage } from '../views/NotVerified';
import { Eident, EidentError } from '../views/Eident';

import { UserProfileConsumer, UserProfileProvider } from '../context/UserProfile';

declare const __VERSION__: string;

type AppProps = {
    isAuthenticated?: boolean;
    isVerified: boolean;
    isTermsAccepted: boolean;
    acceptTerms: (
        marketingAccepted: boolean,
        memberCommunicationAccepted: boolean,
        feedbackAccepted: boolean
    ) => Promise<void>;
    initAuthentication: () => void;
    refreshAuthentication: () => void;
};

export type AppPrivateRouteParams = {
    companyId: string;
};

interface AppState {
    showTermsAcceptedModal: boolean;
}

class PlainApp extends Component<AppProps & WrappedComponentProps> {
    private authRefreshInterval;

    public state: AppState = {
        showTermsAcceptedModal: true
    };

    public componentDidMount() {
        // Do not init/refresh authentication on EidentSuccess page because it's doing the authentication against backend.
        // Initializing or refreshing the auth on that page will cause the application to redirect into landing page and cause
        // the Eident verification to fail.
        if (window.location.pathname !== '/e-ident') {
            this.props.initAuthentication();

            this.authRefreshInterval = setInterval(this.refreshAuth, 60 * 15 * 1000);
        }
    }

    public componentWillUnmount() {
        if (this.authRefreshInterval) {
            clearInterval(this.authRefreshInterval);
        }
    }

    private refreshAuth = () => {
        if (this.props.isAuthenticated === true) {
            this.props.refreshAuthentication();
        }
    };

    public render() {
        const { isAuthenticated, isTermsAccepted } = this.props;

        // Note that `isAuthenticated` may be either `undefined` or `false`. Undefined value indicates
        // that there is a pending authentication refresh request (as initiated by `initAuthentication`
        // during component mount), while `false` means the user is not authenticated. In former case,
        // we should render busy, and in latter case, the unauthenticated routes.

        return (
            <BrowserRouter>
                <Switch>
                    <PublicRoute exact path="/e-ident" component={Eident} />
                    <PublicRoute exact path="/e-ident/error" component={EidentError} />
                    <PublicRoute exact path="/verify" component={NotVerifiedPage} />
                </Switch>

                {isAuthenticated
                    ? this.renderPrivateRoutes(isTermsAccepted)
                    : this.renderPublicRoutes(isAuthenticated === undefined)}
                <Version name={__VERSION__} />
            </BrowserRouter>
        );
    }

    private renderPublicRoutes = (loading: boolean) => {
        const { pathname } = location;

        if (!loading) {
            return (
                <Switch>
                    <PublicRoute exact path="/login" component={LoginPage} />

                    <Redirect exact from="/" to="/login" />
                    <Redirect exact from="/welcome" to="/login" />
                    <Redirect
                        exact
                        from="/:companyId/(home|employments|documents)"
                        to={{ pathname: '/login', state: { referrer: pathname } }}
                    />
                </Switch>
            );
        }
        return <Loader />;
    };

    private renderPrivateRoutes = (isTermsAccepted?: boolean) => {
        const { showTermsAcceptedModal } = this.state;
        const { intl } = this.props;

        return (
            <UserProfileProvider isAuthenticated={true}>
                {isTermsAccepted === false && showTermsAcceptedModal && (
                    <AcceptTermsModal onAcceptTerms={this.onTermsAccepted} />
                )}
                <UserProfileConsumer>
                    {userProfile => {
                        if (userProfile) {
                            const { companyAccess } = userProfile;
                            const defaultCompanyId = Object.keys(companyAccess)[0];

                            if (Object.keys(companyAccess).length > 0) {
                                return (
                                    <Switch>
                                        <Route exact path="/:companyId/home" component={HomePage} />
                                        <Route exact path="/:companyId/employments" component={EmploymentsPage} />
                                        <Route exact path="/:companyId/documents" component={DocumentsPage} />

                                        <Redirect
                                            exact
                                            from="/(|home|welcome|login)?"
                                            to={`/${defaultCompanyId}/home`}
                                        />

                                        <PublicRoute path="*" exact component={NotFound404} />
                                    </Switch>
                                );
                            }
                            return (
                                <Error
                                    description={intl.formatMessage(
                                        { id: 'error.no_access.description' },
                                        { support: intl.formatMessage({ id: 'support.email' }) }
                                    )}
                                />
                            );
                        }
                        return <Error />;
                    }}
                </UserProfileConsumer>
            </UserProfileProvider>
        );
    };

    private onTermsAccepted = async (
        marketingAccepted: boolean,
        memberCommunicationAccepted: boolean,
        feedbackAccepted: boolean
    ) => {
        try {
            await this.props.acceptTerms(marketingAccepted, memberCommunicationAccepted, feedbackAccepted);
        } catch {
            // No op
        }
        this.setState({ showTermsAcceptedModal: false });
    };
}

export const App = compose(
    injectIntl,
    connect(
        ({ auth: { isAuthenticated, isVerified, isTermsAccepted } }: RootState) => ({
            isAuthenticated,
            isVerified,
            isTermsAccepted
        }),
        {
            initAuthentication,
            refreshAuthentication,
            acceptTerms
        }
    )
)(PlainApp);
