import { gql } from '@apollo/client';

export const helttiUserFragment = gql`
    fragment HelttiUserFragment on HelttiUserNode {
        id
        email
        firstName
        lastName
        title

        avatarBigUrl
        avatarSmallUrl
    }
`;

export const companyInvoicingFragment = gql`
    fragment CompanyInvoicingFragment on CompanyInvoicingNode {
        id
        address1
        address2
        zip
        city

        email
        finvoiceAddress
        finvoiceRouterCode
        finvoiceRouterName

        showMembersOnInvoiceAttachment
        sendAttachmentWithInvoice
    }
`;

export const companyFragment = gql`
    fragment CompanyFragment on CompanyNode {
        id
        businessId
        name
        address1
        address2
        zip
        city

        billingTypeSummary
        
        contractStart
        contractEnd

        contractPrice
        contractPriceMobile
        contractStartingFee
        contractNewMemberFee
        contractMinimumBilling

        membersCanReportSickLeave
        sickLeaveMaxDays
        sickLeavePractice

        staff {
            edges {
                node {
                    isPrimary

                    user {
                        ...HelttiUserFragment
                    }
                }
            }
        }
    }

    ${helttiUserFragment}
`;

export const companyInsurancesListFragment = gql`
    fragment CompanyInsurancesListFragment on CompanyInsuranceNode {
        id
        type
        name
        insuranceCompany {
            id
            name
        }
    }
`;

export const insuranceCompanyListFragment = gql`
    fragment InsuranceCompanyListFragment on InsuranceCompanyNode {
        id
        name
    }
`;

export const companyInsurancesFullFragment = gql`
    fragment CompanyInsurancesFullFragment on CompanyInsuranceNode {
        id
        type
        name
        number
        excess
        instructionsMember
        validFrom
        validUntil
        insuranceCompany {
            id
        }
        preferredLocation {
            id
        }
    }
`;

export const locationListFragment = gql`
    fragment LocationListFragment on LocationNode {
        id
        name
    }
`;

export const departmentFragment = gql`
    fragment DepartmentFragment on DepartmentNode {
        id
        name
    }
`;

export const contactDetailFragment = gql`
    fragment ContactDetailFragment on EmploymentMemberContactDetailNode {
        id
        phone
        email
        address1
        address2
        zip
        city
    }
`;

export const memberFragment = gql`
    fragment MemberFragment on MemberNode {
        id
        firstName
        lastName
        ssn

        language {
            id
        }
    }
`;

export const listEmploymentFragment = gql`
    fragment ListEmploymentFragment on EmploymentNode {
        id

        startDate
        endDate
        location

        department {
            ...DepartmentFragment
        }

        member {
            ...MemberFragment
        }
    }

    ${departmentFragment}
    ${memberFragment}
`;

export const employmentFragment = gql`
    fragment EmploymentFragment on EmploymentNode {
        id
        startDate
        endDate

        billingCategory

        position
        superior
        location

        department {
            ...DepartmentFragment
        }

        member {
            ...MemberFragment
        }

        contactDetails {
            ...ContactDetailFragment
        }
    }

    ${memberFragment}
    ${departmentFragment}
    ${contactDetailFragment}
`;

export const companyRepresentativeFragment = gql`
    fragment CompanyRepresentativeFragment on CompanyRepresentativeNode {
        id
        description
        firstName
        lastName
        phone
        email
    }
`;
