import React from 'react';

import { Error, Loader } from '@heltti/components';

import { CompanyQuery } from '../graphql-schema';
import { companyQuery } from '../data/queries';

import { FormatMarkdown } from './FormatMarkdown'
import { useLoadingQuery } from '../hooks/loading-query';

export interface EmploymentSummaryProps {
    companyId: string;
}

export const EmploymentSummary: React.FC<EmploymentSummaryProps> = (props) => {
    const { companyId } = props;

    const skip = companyId === undefined;

    const { data, loading, error } = useLoadingQuery<CompanyQuery>(companyQuery, {
        skip,
        variables: { companyId: companyId }
    });

    const billingSummary = data?.root?.company?.billingTypeSummary

    return (
        <div >
            {error && <Error title={error.name} description={error.message} />}
            {(loading) && (<div> <Loader inline /></div>)}
            <FormatMarkdown source={billingSummary ? billingSummary : ''} />
        </div>
    );
};