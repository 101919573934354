import React, { useCallback, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { gql } from '@apollo/client';
import { Icon, Table } from 'semantic-ui-react';

import { Error, Loader } from '@heltti/components';

import { CompanyDocumentNode, GetCompanyDocumentsQuery } from '../graphql-schema';
import { fileExtensionToIconName } from '../utils/icons';

import t from '../translations';
import { getNodes } from '@heltti/common';
import { useInfiniteLoadingQuery } from '../hooks/infinite-loading-query';

export const GET_COMPANY_DOCUMENTS = 'GetCompanyDocuments';

const QUERY_GET_COMPANY_DOCUMENTS = gql`
    query GetCompanyDocuments(
        $companyId: ID!
        $orderBy: String
        $descending: Boolean
        $query: String
        $first: Int
        $after: String
    ) {
        root {
            company(id: $companyId) {
                id
                documents(first: $first, after: $after, orderBy: $orderBy, descending: $descending, query: $query) {
                    edges {
                        node {
                            id
                            name
                            filename
                            url
                            purpose
                            createDate
                        }
                    }
                    pageInfo {
                        endCursor
                        hasNextPage
                    }
                }
            }
        }
    }
`;

enum SortableDocumentsColumn {
    NAME = 'name',
    CATEGORY = 'purpose',
    CREATE_DATE = 'createDate'
}

type SortOrder = { [key in SortableDocumentsColumn]?: 'ascending' | 'descending' };

export interface DocumentListProps {
    companyId: string;
    query: string;
}

const orderByDesc = (sortOrder: SortOrder): [string, boolean | undefined] => {
    const [[column, order]] = Object.entries(sortOrder);
    return [column, order === 'descending' || undefined];
};

export const DocumentList: React.FC<DocumentListProps> = (props) => {
    const { formatMessage } = useIntl();
    const { companyId, query } = props;
    const [sortOrder, setSortOrder] = useState<SortOrder>({ [SortableDocumentsColumn.NAME]: 'ascending' });
    const [orderBy, descending] = orderByDesc(sortOrder);

    const { data, loading, sentryRef, error } = useInfiniteLoadingQuery<GetCompanyDocumentsQuery>(
        QUERY_GET_COMPANY_DOCUMENTS,
        `root.company.documents`,
        {
            first: 30,
            orderBy,
            descending,
            query
        },
        { variables: { companyId } }
    );
    const hasNextPage = data?.root?.company?.documents?.pageInfo.hasNextPage;
    const documents = getNodes(data?.root?.company?.documents);

    const openDocument = useCallback(
        (document: CompanyDocumentNode) => {
            const { url } = document;
            if (url) {
                window.open(url, '_blank')?.focus();
            }
        },
        [companyId, history]
    );

    const onColumnSortOrderChanged = useCallback(
        (column: SortableDocumentsColumn) => {
            if (sortOrder[column] === 'ascending') {
                setSortOrder({ [column]: 'descending' });
            } else {
                setSortOrder({ [column]: 'ascending' });
            }
        },
        [sortOrder, setSortOrder]
    );

    return (
        <div>
            {error && <Error title={error.name} description={error.message} />}

            <Table selectable sortable celled compact singleLine>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell
                            sorted={sortOrder[SortableDocumentsColumn.NAME]}
                            onClick={() => onColumnSortOrderChanged(SortableDocumentsColumn.NAME)}
                        >
                            {formatMessage(t.documentName)}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            width="five"
                            sorted={sortOrder[SortableDocumentsColumn.CATEGORY]}
                            onClick={() => onColumnSortOrderChanged(SortableDocumentsColumn.CATEGORY)}
                        >
                            {formatMessage(t.documentPurpose)}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            width="three"
                            sorted={sortOrder[SortableDocumentsColumn.CREATE_DATE]}
                            onClick={() => onColumnSortOrderChanged(SortableDocumentsColumn.CREATE_DATE)}
                        >
                            {formatMessage(t.documentCreated)}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {documents.map((document) => (
                        <DocumentRow key={document.id} document={document} onClick={() => openDocument(document)} />
                    ))}
                    {(hasNextPage || loading) && (
                        <Table.Row>
                            <Table.Cell colSpan={5} textAlign="center">
                                {/* Sentry component, see https://www.npmjs.com/package/react-infinite-scroll-hook */}
                                <div ref={sentryRef}>
                                    <Loader inline />
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    )}
                    {!documents?.length ? (
                        <Table.Row>
                            <Table.Cell colSpan="3">
                                {query?.length
                                    ? formatMessage(t.documentsSearchNoResults)
                                    : formatMessage(t.documentsNone)}
                            </Table.Cell>
                        </Table.Row>
                    ) : null}
                </Table.Body>
            </Table>
        </div>
    );
};

const purposeToCategoryName = (purpose: string) => <FormattedMessage id={`document.purpose.${purpose}`} />;

type DocumentRowProps = {
    document: CompanyDocumentNode;
    onClick: () => void;
};

const DocumentRow: React.FC<DocumentRowProps> = (props) => {
    const { document, onClick } = props;
    const { name, filename, purpose, createDate } = document;

    return (
        <Table.Row onClick={onClick}>
            <Table.Cell>
                <Icon
                    name={fileExtensionToIconName(filename)}
                    style={{ fontSize: '1.1em', color: '#888888', marginRight: '10px' }}
                />
                {name}
            </Table.Cell>
            <Table.Cell>{purposeToCategoryName(purpose) ?? purpose}</Table.Cell>
            <Table.Cell>
                <FormattedDate value={createDate} />
            </Table.Cell>
        </Table.Row>
    );
};
