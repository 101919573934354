import { auth, AuthState } from './auth';

export type RootState = {
    auth: AuthState;
};

export * from './auth';

export const reducers = {
    auth
};
