import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FinnishSSN } from 'finnish-ssn';
import { fi } from 'date-fns/locale';
import { useParams } from 'react-router';

import DatePicker, { registerLocale } from 'react-datepicker';
import { Divider, DropdownProps, Form, Header, Message, Modal, Transition } from 'semantic-ui-react';
import { Button } from '@heltti/components';

import { translatedBillingCategoryOptions } from '../translations/utils';
import { languages } from '../library/constants';

import 'react-datepicker/dist/react-datepicker.css';
import { companyDepartmentsQuery } from '../data/queries';
import { AppPrivateRouteParams } from './App';
import { getNodes } from '@heltti/common';
import { CompanyDepartmentsQuery } from '../graphql-schema';
import {
    DropdownItemProps,
    StrictDropdownItemProps
} from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import { useLoadingQuery } from '../hooks/loading-query';

registerLocale('fi', fi);

export interface EmploymentFormProps {
    mode: 'edit' | 'create';
    open?: boolean;
    loading?: boolean;
    validate?: boolean;
    initialValues: EmploymentFormData;
    onSubmit: (values: EmploymentFormData) => Promise<void>;
    onCancel: () => Promise<void>;
}

export type EmploymentFormData = {
    startDate: Date;
    endDate: Date | null;
    position: string;
    superior: string;
    location: string;
    department?: string;
    billingCategory: string;
    memberFirstName: string;
    memberLastName: string;
    memberSsn: string;
    memberLanguageId: string;
    contactDetailsPhone: string;
    contactDetailsEmail: string;
    contactDetailsAddress1: string;
    contactDetailsAddress2: string;
    contactDetailsZip: string;
    contactDetailsCity: string;
};

const languageOptions = Object.entries(languages).map(([key, { name }]) => {
    return {
        key: parseInt(key, 10),
        value: `LanguageNode:${parseInt(key, 10)}`,
        text: name
    };
});

const schema = (intl: IntlShape) => {
    const required = intl.formatMessage({ id: 'form.validate.required' });
    const invalidEmail = intl.formatMessage({ id: 'form.validate.invalid_email' });
    const invalidSsn = intl.formatMessage({ id: 'form.validate.invalid_ssn' });
    const invalidZip = intl.formatMessage({ id: 'form.validate.invalid_zip' });

    return yup.object().shape({
        memberFirstName: yup.string().required(required),
        memberLastName: yup.string().required(required),
        memberSsn: yup
            .string()
            .test('valid-ssn', invalidSsn, (value) => FinnishSSN.validate(value ?? ''))
            .required(required),
        memberLanguageId: yup.string().required(required),
        contactDetailsPhone: yup.string().required(required),
        contactDetailsEmail: yup.string().required(required).email(invalidEmail),
        contactDetailsZip: yup.string().matches(/^[0-9]{5}$/, invalidZip),
        billingCategory: yup.string().required(required),
        startDate: yup.date().nullable().required(required),
        endDate: yup.date().nullable()
    });
};

export const EmploymentForm: React.FC<EmploymentFormProps> = (props) => {
    const intl = useIntl();
    const { companyId } = useParams<AppPrivateRouteParams>();

    const { mode, loading, open, validate, initialValues, onSubmit, onCancel } = props;

    const { loading: loadingDepartments, data: departmentData } = useLoadingQuery<CompanyDepartmentsQuery>(
        companyDepartmentsQuery,
        {
            variables: { companyId }
        }
    );
    const [addedDepartments, setAddedDepartments] = useState<string[]>([]);

    const onAddDepartment = useCallback(
        (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
            if (typeof data.value === 'string') {
                setAddedDepartments([...addedDepartments, data.value]);
            }
        },
        [addedDepartments]
    );

    const departments = getNodes(departmentData?.root?.company?.departments);

    const { handleChange, handleSubmit, validateForm, values, errors, isValid, setFieldValue } =
        useFormik<EmploymentFormData>({
            initialValues,
            onSubmit,
            validationSchema: schema(intl)
        });

    const departmentOptions: StrictDropdownItemProps[] = useMemo(() => {
        const backendDepartments = departments.map((department) => ({ value: department.id, text: department.name }));
        const addedDepartmentOptions = addedDepartments.map((name) => ({ value: name, text: name }));

        return [...backendDepartments, ...addedDepartmentOptions].sort((a, b) => a.text.localeCompare(b.text));
    }, [departments, addedDepartments]);

    useEffect(() => {
        if (validate) {
            void validateForm();
        }
    }, [validate, validateForm]);

    const renderMemberFields = () => {
        const {
            memberFirstName,
            memberLastName,
            memberSsn,
            contactDetailsPhone,
            contactDetailsEmail,
            memberLanguageId
        } = values;
        return (
            <>
                <Header as="h3">
                    <FormattedMessage id="form.employment.basic_info" />
                </Header>

                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input
                            autoComplete="none"
                            required={mode === 'create'}
                            readOnly={mode === 'edit'}
                            id="memberFirstName"
                            name="memberFirstName"
                            value={memberFirstName}
                            onChange={handleChange}
                            error={!!errors?.memberFirstName}
                            label={intl.formatMessage({ id: 'form.employment.basic_info.first_name' })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            autoComplete="none"
                            required={mode === 'create'}
                            readOnly={mode === 'edit'}
                            id="memberLastName"
                            name="memberLastName"
                            value={memberLastName}
                            onChange={handleChange}
                            error={!!errors?.memberLastName}
                            label={intl.formatMessage({ id: 'form.employment.basic_info.last_name' })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            autoComplete="none"
                            required={mode === 'create'}
                            readOnly={mode === 'edit'}
                            id="memberSsn"
                            name="memberSsn"
                            value={memberSsn}
                            onChange={handleChange}
                            error={!!errors?.memberSsn}
                            label={intl.formatMessage({ id: 'form.employment.basic_info.ssn' })}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input
                            autoComplete="none"
                            required={mode === 'create'}
                            id="contactDetailsEmail"
                            name="contactDetailsEmail"
                            value={contactDetailsEmail}
                            onChange={handleChange}
                            error={!!errors?.contactDetailsEmail}
                            label={intl.formatMessage({ id: 'form.employment.basic_info.email' })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            autoComplete="none"
                            required={mode === 'create'}
                            id="contactDetailsPhone"
                            name="contactDetailsPhone"
                            value={contactDetailsPhone}
                            onChange={handleChange}
                            error={!!errors?.contactDetailsPhone}
                            label={intl.formatMessage({ id: 'form.employment.basic_info.phone' })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Select
                            autoComplete="none"
                            required={mode === 'create'}
                            id="memberLanguageId"
                            name="memberLanguageId"
                            value={memberLanguageId}
                            options={languageOptions}
                            onChange={(_, data: DropdownProps) => {
                                void setFieldValue('memberLanguageId', data.value);
                            }}
                            label={intl.formatMessage({ id: 'form.employment.basic_info.language' })}
                            placeholder={intl.formatMessage({ id: 'form.employment.basic_info.language' })}
                            error={!!errors?.memberLanguageId}
                        />
                    </Form.Field>
                </Form.Group>
            </>
        );
    };

    const renderAddressFields = () => {
        const { contactDetailsAddress1, contactDetailsAddress2, contactDetailsCity, contactDetailsZip } = values;
        return (
            <>
                <Header as="h3">
                    <FormattedMessage id="form.employment.address_info" />
                </Header>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input
                            autoComplete="none"
                            name="contactDetailsAddress1"
                            value={contactDetailsAddress1}
                            onChange={handleChange}
                            error={!!errors?.contactDetailsAddress1}
                            label={intl.formatMessage({ id: 'form.employment.address_info.address1' })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            autoComplete="none"
                            name="contactDetailsAddress2"
                            value={contactDetailsAddress2}
                            onChange={handleChange}
                            error={!!errors?.contactDetailsAddress2}
                            label={intl.formatMessage({ id: 'form.employment.address_info.address2' })}
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input
                            name="contactDetailsCity"
                            value={contactDetailsCity}
                            onChange={handleChange}
                            error={!!errors?.contactDetailsCity}
                            label={intl.formatMessage({ id: 'form.employment.address_info.city' })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            name="contactDetailsZip"
                            value={contactDetailsZip}
                            onChange={handleChange}
                            error={!!errors?.contactDetailsZip}
                            label={intl.formatMessage({ id: 'form.employment.address_info.zip' })}
                        />
                    </Form.Field>
                </Form.Group>
            </>
        );
    };

    const renderEmploymentFields = () => {
        const { location, department, position, superior, billingCategory, startDate, endDate } = values;

        return (
            <>
                <Header as="h3">
                    <FormattedMessage id="form.employment.employment_info" />
                </Header>

                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input
                            name="location"
                            value={location}
                            onChange={handleChange}
                            error={!!errors?.location}
                            label={intl.formatMessage({ id: 'form.employment.employment_info.location' })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Select
                            allowAdditions
                            clearable
                            additionLabel={intl.formatMessage({ id: 'form.addSelectOption' })}
                            additionPosition="top"
                            search={(options: DropdownItemProps[], value: string) =>
                                departmentOptions.filter((option) =>
                                    (option.text as string | undefined)?.toLowerCase().includes(value.toLowerCase())
                                )
                            }
                            autoComplete="none"
                            id="department"
                            name="department"
                            value={department}
                            options={departmentOptions}
                            onAddItem={onAddDepartment}
                            onChange={(_, data: DropdownProps) => {
                                void setFieldValue('department', data.value);
                            }}
                            label={intl.formatMessage({ id: 'form.employment.employment_info.department' })}
                            placeholder={intl.formatMessage({ id: 'form.employment.employment_info.department' })}
                            error={!!errors?.department}
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input
                            name="position"
                            value={position}
                            onChange={handleChange}
                            error={!!errors?.position}
                            label={intl.formatMessage({ id: 'form.employment.employment_info.position' })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            autoComplete="none"
                            name="superior"
                            value={superior}
                            onChange={handleChange}
                            error={!!errors?.superior}
                            label={intl.formatMessage({ id: 'form.employment.employment_info.superior' })}
                        />
                    </Form.Field>
                </Form.Group>

                <Header as="h3">
                    {intl.formatMessage({ id: 'form.employment.employment_info.validity_and_invoicing' })}
                </Header>

                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Select
                            required
                            id="billingCategory"
                            name="billingCategory"
                            value={billingCategory}
                            options={translatedBillingCategoryOptions(intl)}
                            onChange={(_, data: DropdownProps) => {
                                void setFieldValue('billingCategory', data.value);
                            }}
                            label={intl.formatMessage({ id: 'form.employment.employment_info.billing_category' })}
                            placeholder={intl.formatMessage({ id: 'form.employment.employment_info.billing_category' })}
                            error={!!errors?.billingCategory}
                        />
                    </Form.Field>

                    <Form.Field
                        required
                        name="startDate"
                        control={DatePicker}
                        label={intl.formatMessage({ id: 'employees.employment.start' })}
                        placeholderText={intl.formatMessage({ id: 'employees.employment.start' })}
                        disabled={mode === 'edit'}
                        selected={startDate}
                        onChange={(date) => setFieldValue('startDate', date)}
                        minDate={new Date()}
                        maxDate={endDate}
                        showYearDropdown
                        locale="fi"
                        dateFormat="P"
                        error={!startDate || !!errors?.startDate}
                    />

                    <Form.Field
                        name="endDate"
                        control={DatePicker}
                        placeholderText={intl.formatMessage({ id: 'employees.employment.end' })}
                        label={intl.formatMessage({ id: 'employees.employment.end' })}
                        selected={endDate}
                        onChange={(date) => setFieldValue('endDate', date)}
                        minDate={startDate && Date.now() < startDate.getTime() ? startDate : new Date()}
                        showYearDropdown
                        locale="fi"
                        dateFormat="P"
                        error={!!errors?.endDate}
                    />
                </Form.Group>
            </>
        );
    };

    const { endDate } = values;

    return (
        <Transition transitionOnMount unmountOnHide animation="fade" duration={200} visible={open}>
            <Modal
                open
                noValidate
                as={Form}
                warning={!!endDate}
                onSubmit={handleSubmit}
                loading={loadingDepartments}
                error={!isValid}
                onClose={() => close()}
                centered={false}
            >
                <Modal.Header>
                    <FormattedMessage id={`form.employment.title.${mode}`} />
                </Modal.Header>
                <Modal.Content>
                    {renderMemberFields()}
                    {renderAddressFields()}
                    {renderEmploymentFields()}

                    {!!endDate && <Divider hidden />}
                    <Message
                        warning
                        header={intl.formatMessage({ id: 'form.employment.employment_info.warning.title' })}
                        content={intl.formatMessage({ id: 'form.employment.employment_info.warning.content' })}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        basic
                        onClick={(e) => {
                            e.preventDefault();
                            void onCancel();
                        }}
                    >
                        <FormattedMessage id="form.employment.cancel" />
                    </Button>
                    <Button primary formNoValidate type="submit" disabled={!isValid} loading={loading}>
                        <FormattedMessage id="form.employment.save" />
                    </Button>
                </Modal.Actions>
            </Modal>
        </Transition>
    );
};
