import React from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor, PrimitiveType } from 'react-intl';
import {
    gatherMessageDescriptors,
    gatherTranslations,
    TranslationLanguage,
    TranslationRootCollection
} from '@heltti/common';

import fiMessages from '../translations/fi.json';
import enMessages from '../translations/en.json';

const oldMessagesByLanguage: Record<'fi' | 'en', Record<string, string>> = {
    fi: fiMessages,
    en: enMessages
};

// TODO: This is deprecated - please add translations to translationRootCollection below
// eslint-disable-next-line import/no-default-export
export default defineMessages({
    hi: { id: 'hi', defaultMessage: 'Hi' },
    cancel: { id: 'cancel', defaultMessage: 'Cancel' },

    errorGeneralTitle: { id: 'error.general.title', defaultMessage: 'Oops!' },
    errorGeneralDescription: {
        id: 'error.general.description',
        defaultMessage: 'Something went wrong, apologies! Please contact Heltti if this happens again.'
    },
    errorGeneralRefresh: {
        id: 'error.general.refresh',
        defaultMessage: 'Refresh the page'
    },
    errorNotFoundTitle: { id: 'error.notFound.title', defaultMessage: 'Not found' },
    errorNotFoundDescription: {
        id: 'error.notFound.description',
        defaultMessage: 'Please try some other link.'
    },

    menuHome: { id: 'menu.home', defaultMessage: 'NotFound404' },

    homeTitle: { id: 'home.title', defaultMessage: 'Welcome' },

    loginFailedCredentials: {
        id: 'login.failed.credentials',
        defaultMessage: 'Wrong credentials'
    },
    loginUsername: { id: 'login.username', defaultMessage: 'Username' },
    loginPassword: { id: 'login.password', defaultMessage: 'Password' },
    loginButton: { id: 'login.button', defaultMessage: 'Log in' },
    loginTitle: { id: 'login.title', defaultMessage: 'Log in to MyHeltti' },
    logoutButton: { id: 'logout.button', defaultMessage: 'Log out' },

    documentsTitle: { id: 'document.title', defaultMessage: 'Documents' },
    documentsSearch: {
        id: 'document.search',
        defaultMessage: 'Search document by name...'
    },
    documentsSearchNoResults: {
        id: 'document.search.no_results',
        defaultMessage: 'No results'
    },
    documentsNone: { id: 'document.none', defaultMessage: 'No documents' },
    summaryTitle: { id: 'home.summary', defaultMessage: 'Summary' },
    profileTitle: { id: 'profile.title', defaultMessage: 'Profile' },
    profileInfo: { id: 'profile.info', defaultMessage: 'Info' },

    employeeAddress: { id: 'employees.address', defaultMessage: 'Address' },
    employeeAddress1: { id: 'employees.address1', defaultMessage: 'Address 1' },
    employeeAddress2: { id: 'employees.address2', defaultMessage: 'Address 2' },
    employeeBasicInfo: { id: 'employees.basicInfo', defaultMessage: 'Basic info' },
    employeeCity: { id: 'employees.city', defaultMessage: 'City' },
    employeeEmail: { id: 'employees.email', defaultMessage: 'Email' },
    employeeName: { id: 'employees.name', defaultMessage: 'Name' },
    employeePhone: { id: 'employees.phone', defaultMessage: 'Phone number' },
    employeeSSN: { id: 'employees.ssn', defaultMessage: 'SSN' },
    employeeZip: { id: 'employees.zip', defaultMessage: 'Zip' },

    employeesTitle: { id: 'employees.title', defaultMessage: 'Employees' },
    employeesActive: { id: 'employees.active', defaultMessage: 'Active employments' },
    employeesInactive: { id: 'employees.inactive', defaultMessage: 'Ended employments' },
    employments: { id: 'employees.employments', defaultMessage: 'Employments' },
    employment: { id: 'employees.employment', defaultMessage: 'Employment' },
    employmentsSummary: { id: 'employees.employments.summary', defaultMessage: 'Summary' },
    employmentSearch: {
        id: 'employees.employment.search',
        defaultMessage: 'Search by name...'
    },
    employmentSearchNoResults: {
        id: 'employees.employment.search.no_results',
        defaultMessage: 'No results'
    },
    employmentNone: { id: 'employees.employment.none', defaultMessage: 'No employments' },
    employmentAdd: { id: 'employees.employment.create', defaultMessage: 'Add employee' },
    employmentNew: { id: 'employees.employment.new', defaultMessage: 'New' },
    employmentStart: {
        id: 'employees.employment.start',
        defaultMessage: 'Employment start'
    },
    employmentEnd: { id: 'employees.employment.end', defaultMessage: 'Employment end' },
    employmentLocation: { id: 'employees.employment.location', defaultMessage: 'Location' },
    employmentDepartment: {
        id: 'employees.employment.department',
        defaultMessage: 'Department'
    },
    employmentPosition: { id: 'employees.employment.position', defaultMessage: 'Position' },
    employmentSuperior: { id: 'employees.employment.superior', defaultMessage: 'Superior' },
    employmentCategory: { id: 'employees.employment.category', defaultMessage: 'Type' },
    employmentCategoryFullTime: {
        id: 'employees.employment.full_time',
        defaultMessage: 'Full time'
    },
    employmentCategoryPartTime: {
        id: 'employees.employment.part_time',
        defaultMessage: 'Part time'
    },
    employmentCategoryIncident: {
        id: 'employees.employment.incident',
        defaultMessage: 'Incident'
    },
    employmentCategoryMobile: {
        id: 'employees.employment.mobile',
        defaultMessage: 'Mobile'
    },
    employmentCategoryIncidentKela2: {
        id: 'employees.employment.incidentKela2',
        defaultMessage: 'Incident Kela 2 part time'
    },
    employmentValidity: {
        id: 'employees.employment.validity',
        defaultMessage: 'Employment validity'
    },
    employeeLayOffMessage: {
        id: 'employees.layOffMessage',
        defaultMessage:
            'Did you know that employees are entitled to occupational health care services during a temporary lay-off? Also, the employees whose contract has been terminated due to financial and production-related causes are entitled to occupational healthcare services for 6 months starting from the last working day, if the employment has lasted longer than 5 years and the company size exceeds 30 employees.'
    },
    selectCompany: { id: 'company.select', defaultMessage: 'Select' },
    switchCompany: { id: 'company.switch', defaultMessage: 'Log in to another company' },
    documentAdd: { id: 'document.add', defaultMessage: 'Add document' },
    documentName: { id: 'document.name', defaultMessage: 'File' },
    documentDescription: { id: 'document.description', defaultMessage: 'Description' },
    documentFilename: { id: 'document.filename', defaultMessage: 'File name' },
    documentPurpose: { id: 'document.purpose', defaultMessage: 'Category' },
    documentSave: { id: 'document.save', defaultMessage: 'Save' },
    documentCreated: { id: 'document.created', defaultMessage: 'Created at' },
    documentExpires: { id: 'document.expires', defaultMessage: 'Expires' },
    memberSave: { id: 'member.save', defaultMessage: 'Save changes' },

    invoiceNumber: { id: 'invoice.number', defaultMessage: 'Invoice number' },
    invoiceDate: { id: 'invoice.date', defaultMessage: 'Date' },
    invoiceTotalAmount: { id: 'invoice.totalAmount', defaultMessage: 'Total' },
    invoiceAttachments: {
        id: 'invoice.attachments',
        defaultMessage: 'Invoice attachments'
    },

    profileNoDocuments: {
        id: 'profile.documents.none',
        defaultMessage: 'You have no documents'
    },
    profileProfileInfo: {
        id: 'profile.profileInfo',
        defaultMessage: 'Profile information'
    },
    profileFirstName: { id: 'profile.firstName', defaultMessage: 'First name' },
    profileLastName: { id: 'profile.lastName', defaultMessage: 'Last name' },
    profileLanguage: { id: 'profile.language', defaultMessage: 'Language' },
    profileSave: { id: 'profile.save', defaultMessage: 'Save' },

    companyAddress: { id: 'company.address', defaultMessage: 'Address' },
    companyInfo: { id: 'company.info', defaultMessage: 'Company information' },
    companyId: { id: 'company.id', defaultMessage: 'Business ID' },
    companyName: { id: 'company.name', defaultMessage: 'Name' },
    company: { id: 'company.save', defaultMessage: 'Save changes' },
    companyContactPersons: {
        id: 'company.contact.persons',
        defaultMessage: 'Contact person'
    },
    companyBilling: { id: 'company.billing', defaultMessage: 'Billing' },
    companyBillingAddress: {
        id: 'company.billing.address',
        defaultMessage: 'Billing address'
    },
    companyBillingEmail: { id: 'company.billing.email', defaultMessage: 'Billing email' },
    companyFiscalYear: {
        id: 'form.company.basic_info.fiscal',
        defaultMessage: 'Fiscal year'
    },
    companyContract: { id: 'company.contract', defaultMessage: 'Contract' },
    companyBillingtypeSummary: { id: 'company.billingtype.summary', defaultMessage: 'Billingtype summary' },
    companyContractPrice: { id: 'company.contract.price', defaultMessage: 'Full time' },
    companyContractPriceMobile: {
        id: 'company.contract.price.mobile',
        defaultMessage: 'Mobile'
    },
    companyStaff: { id: 'company.staff', defaultMessage: 'Your Heltti staff' },
    companyContractMinimumBilling: {
        id: 'company.contract.minimumBilling',
        defaultMessage: 'Minimum billing'
    },
    companyContractNewMemberFee: {
        id: 'company.contract.newMemberFee',
        defaultMessage: 'New member fee'
    },

    sickLeavePracticeDescription: {
        id: 'sickLeavePractice.description',
        defaultMessage: 'Description'
    },
    sickLeavePracticeSelfReportedMaxDays: {
        id: 'sickLeavePractice.selfReportedMaxDays',
        defaultMessage: 'Grant by own report max days'
    },
    sickLeavePracticeTitle: {
        id: 'sickLeavePractice.title',
        defaultMessage: 'Sick leave practice'
    },

    staffUserIsPrimary: { id: 'company.staff.user.isPrimary', defaultMessage: 'Primary' },
    staffUserIsDeputy: { id: 'company.staff.user.isDeputy', defaultMessage: 'Deputy' },

    invalidPhoneNumber: {
        id: 'validation.phone',
        defaultMessage: 'Invalid phone number. Number should be in the form: +35801234567'
    },
    genericFormError: {
        id: 'error.form.generic',
        defaultMessage:
            'An error occurred. Please check the fields and if problems persist contact helttiview-tuki@heltti.fi'
    },
    invoicingTitle: { id: 'invoicing.title', defaultMessage: 'Invoicing' },

    memberDataIntegrationEnabled: {
        id: 'dataIntegration.memberDataIntegrationEnabled',
        defaultMessage: 'Employments of this company are managed automatically.'
    },
    sickLeaveDataIntegrationEnabled: {
        id: 'dataIntegration.sickLeaveDataIntegrationEnabled',
        defaultMessage: 'Sick leave data of employees of this company are handled automatically.'
    }
});

const translationRootCollection: TranslationRootCollection = {
    address: {
        streetAddress: { en: 'Street address', fi: 'Katuosoite' },
        zip: { en: 'Zip', fi: 'Postinumero' },
        city: { en: 'City', fi: 'Postitoimipaikka' }
    },
    form: {
        save: { en: 'Save', fi: 'Tallenna' }
    },
    invoicing: {
        title: { en: 'Invoicing', fi: 'Laskutus' },
        address: { en: 'Address', fi: 'Osoite' },
        email: { en: 'Invoicing email', fi: 'Sähköpostilaskuosoite' },
        electronicInvoicing: { en: 'Electronic invoicing', fi: 'Sähköinen laskutus' },
        finvoiceAddress: { en: 'Finvoice address', fi: 'Verkkolaskuosoite' },
        finvoiceRouter: { en: 'Finvoice router', fi: 'Verkkolaskuoperaattori' },
        sendAttachmentWithInvoice: {
            en: 'Send invoice attachments with invoice',
            fi: 'Lähetä laskun liitteet laskun mukana'
        },
        showMembersOnInvoiceAttachment: {
            en: 'Include member list on invoice attachments',
            fi: 'Jäsenlista laskun liitteellä'
        }
    },
    yesNo: {
        yes: { en: 'Yes', fi: 'Kyllä' },
        no: { en: 'No', fi: 'Ei' }
    }
};

export const getMessages = (language: TranslationLanguage) => {
    const oldMessages = oldMessagesByLanguage[language];

    return {
        ...oldMessages,
        ...gatherTranslations(language, translationRootCollection)
    };
};

export const translations = gatherMessageDescriptors(translationRootCollection);

// TODO: Move Translation component to @heltti/components
type TranslationProps = {
    for: MessageDescriptor;
    values?: { [key: string]: PrimitiveType | React.ReactNode };
};

export const Translation = (props: TranslationProps) => {
    if (props?.for?.id === undefined) {
        throw Error('Invalid translation name');
    }

    return <FormattedMessage id={props.for.id} values={props.values} />;
};
