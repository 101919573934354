import React from 'react';
import ReactMarkdown from 'react-markdown';

import breaks from 'remark-breaks';

interface Props {
    source: string;
}

export const FormatMarkdown: React.FC<Props> = props => {
    const { source } = props;

    return (
        <ReactMarkdown
            unwrapDisallowed
            remarkPlugins={[breaks]}
            className="markdown"
        >
            {source}
        </ReactMarkdown>
    );
};
